// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// Add Styles
import '../styles/application.scss';

// Add Rails JS
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery")

// Add Stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context('./controllers', true, /.js$/);
application.load(definitionsFromContext(context));

// Add Websockets
require("channels"); 

// Add Components
import './components';

// Add Images
require.context('../images', true, /\.(jpg|png|svg)$/i)