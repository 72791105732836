// A piece of code for Notification Interactions
document.addEventListener("turbolinks:load", function() {
  // initialize code
  document
    .querySelectorAll("#payment-notification .delete")
    .forEach(input => {
      input.addEventListener("focus", function() {
        document
          .getElementById("payment-notification")
          .classList.add("is-hidden");
      });
    });

  document.querySelectorAll(".notification > button.delete").forEach(input =>
    input.addEventListener("focus", function() {
      this.parentNode.classList.add("is-hidden");
    })
  );

  document.querySelectorAll(".alert").forEach(input =>
    input.addEventListener("click", function() {
      this.classList.add("d-none");
    })
  );

  //Remove notifications after seconds
  document.querySelectorAll('.timed-not').forEach(e => checkText(e));
  
  function checkText(object){
    
    var span = document.getElementById('notification-text');
    var text = span.innerText;
    var text_id = text.indexOf('[fixed]');

    if(text_id < 0){
      hideNotificationTimer(object)
    }else{
      span.innerText = removeTextTag(text);
    }
  }  

  function hideNotificationTimer(e){
    setTimeout(function(){ 
      e.classList.add('is-hidden');
    }, 5000);
  }

  function removeTextTag(text){
    var new_text = text.replace('[fixed]','');
    return new_text;
  }

});
