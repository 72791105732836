// javascript/controllers/cart-item.js
import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return [ 'update', 'remove' ];
  }

  connect() {}
  disconnect() {}

  update(event){
    Rails.fire(this.updateTarget, 'submit');
  }

  remove(event){
    Rails.fire(this.removeTarget, 'submit');
  }
  
  onChangeOk(event){
    Turbolinks.clearCache();
    Turbolinks.visit(location, {action: 'replace'});
  }
}
