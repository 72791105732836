var phone_present = false;
var phone_check = false;


document.addEventListener("turbolinks:load", function () {
  if (document.getElementById("user_phone")) {
    document
        .getElementById("user_phone")
        .addEventListener("input", function (e) {
          /*e.target.value = cleanValor(e.target.value)
          e.target.value = parseFloat(e.target.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})*/
        });
  }

  // var input = document.getElementById("user_phone");
  // var footer_phone = document.getElementById("cart_phone");

  if (document.getElementById("user_phone")) {
    phone_present = true;
    //console.log("Phone present : " + phone_present)
    checkPhone("start");
    $("#user_phone").keyup(function () {
      checkPhone("x");
    });
  }


  function checkPhone(value) {
    let str = $("#user_phone").val();
    // console.log("Phone Number : " + str)
    // console.log("Phone Number len: " + str.length)
    //console.log("Number size : " + str.length)
    let ddi = '';

    if (document.getElementById("ddi_code")) {
      ddi = document.getElementById("ddi_code").value.split('+')[1];
    }

    if ((ddi == '55') || (ddi == '')) {
      checkPhoneLength(value, str, 11)
    } else if (ddi == '1') {
      checkPhoneLength(value, str, 10)
    } else {
      checkPhoneLength(value, str, 5)
    }
    checkOk();
  }

  function checkPhoneLength(value, str, max_length){
    if (str.length < max_length) {
      phone_check = false
      if (value != "start") {
        $("#phoneError").removeClass("is-hidden");
      }
      if (value == 'start') {
        $("#user_phone").val("");
      }
    } else {
      phone_check = true
      if (value != "start") {
        $("#phoneError").addClass("is-hidden");
      }

    }
  }

  function checkOk() {
    if(document.getElementById('card-submit')) {
      if (phone_present == true && phone_check == true) {
        $("#card-submit").attr("disabled", false);
      } else {
        $("#card-submit").attr("disabled", true);
      }
    }
  }

  if(document.getElementById('checkout-button') && document.getElementById('user_phone')) {
    if($("#user_phone").val().length < 5){
      $("#cart_phone").val("");
    }
    $("#user_phone").on('keyup', function(){
      if($("#user_phone").val().length < 5){
        $("#cart_phone").val("");
      }else{
        $("#cart_phone").val($("#ddi_code").val().split("+")[1]+$("#user_phone").val());
      }
      // console.log("cart_phone value: "+ document.getElementById("cart_phone").value);
    });
    $("#cart_phone").val("");
  }

  $("#user_phone").on('change', function(){
    if($("#user_phone").val().length > 5){
      if(document.getElementById('ddi_code')) {
        $("#cart_phone").val($("#ddi_code").val().split("+")[1]+$("#user_phone").val());
      }
      // $("#cart_phone").val($("#ddi_code").val().split("+")[1]+$("#user_phone").val());

      //console.log("footer phone value: "+ document.getElementById("cart_phone").value)
    }
  });

})
