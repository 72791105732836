document.addEventListener("turbolinks:load", function (event) {


    if (document.getElementById("docType")) {
  
      var docType = document.getElementById("docType");
      var cpf = document.getElementById("docNumber_front");
      var real_cpf = document.getElementById("docNumber");
      var success_message = document.getElementById("cpf-success-label");
      var error_message = document.getElementById("cpf-error-label");
  
      
  
      cpf.addEventListener("keyup", function (event) {
      
        if(docType.options[docType.selectedIndex].index == 0){

          var test = cleanCpf(cpf.value);
          //console.log(test);
          test = TestCPF(test);

          if (test == true) {
            let name, arr;
            name = "is-success";
            arr = cpf.className.split(" ");
            if (arr.indexOf(name) == -1) {
              cpf.className += " " + name;
            }
            success_message.innerText = "";
            error_message.innerText = "";
            cpf.value = formatCPF(cpf.value); // CPF on the screen
            real_cpf.value = cleanCpf(cpf.value); // CPF that will be send to MP
          } else {
            success_message.innerText = "";
            error_message.innerText = "";
            let name = "is-success";
            arr = cpf.className.split(" ");
            if (arr.indexOf(name) > -1) {
              cpf.className = cpf.className.replace("is-success", "");
            }
            if (cpf.value.length >= 11) {
              error_message.innerText = "Verifique o número do CPF";
              cpf.value = cpf.value.replace(/\./g, "");
              cpf.value = cpf.value.replace(/\-/g, "");
            }
          }
        }else{
          let name = "is-success";
          arr = cpf.className.split(" ");
          if (arr.indexOf(name) > -1) {
            cpf.className = cpf.className.replace("is-success", "");
          }
          success_message.innerText = "";
          error_message.innerText = "";
        }
      });
    }
  });
  
  function TestCPF(cpf) {
    var numeros, digitos, soma, i, resultado, digitos_iguais;
      digitos_iguais = 1;
      if (cpf.length < 11)
            return false;
      for (i = 0; i < cpf.length - 1; i++)
            if (cpf.charAt(i) != cpf.charAt(i + 1))
                  {
                  digitos_iguais = 0;
                  break;
                  }
      if (!digitos_iguais)
            {
            numeros = cpf.substring(0,9);
            digitos = cpf.substring(9);
            soma = 0;
            for (i = 10; i > 1; i--)
                  soma += numeros.charAt(10 - i) * i;
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                  return false;
            numeros = cpf.substring(0,10);
            soma = 0;
            for (i = 11; i > 1; i--)
                  soma += numeros.charAt(11 - i) * i;
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                  return false;
            return true;
            }
      else
            return false;
  }
  
  function formatCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  function cleanCpf(val){
    val = val.replace(/\./g, "");
    val = val.replace(/\-/g, "");
    return val;
  }