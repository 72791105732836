document.addEventListener("turbolinks:load", function () {

    //GENERIC CALL MODAL
    function callModal(modal_id) {
        //CHECK IF THERE IS A MODAL INSTANCE
        if (document.getElementById(modal_id)) {
            $(".glin-modal").addClass('is-active');
        } else {
            renderModal(modal_id);
        }
    }

    //AJAX TO RENDER MODAL
    function renderModal(partial_name) {
        $.ajax({
            type: "GET",
            url: "/show_modal",
            dataType: 'script',
            data: {
                modal_partial: partial_name
            },
            success: function (response) {
                modal_first_load = true;
            }
        });
    }

    //SIMULATOR MODAL
    $(".call-modal").on("click", function () {
        callModal($(this).attr('partial-name'));
    });

});
