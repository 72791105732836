document.addEventListener('turbolinks:load', function (event) {
    if ($(".to-copy")) {
        $(".to-copy").on("click", function () {
            if (window.getSelection) {
                if (window.getSelection().empty) { // Chrome
                    window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) { // Firefox
                    window.getSelection().removeAllRanges();
                }
            } else if (document.selection) { // IE?
                document.selection.empty();
            }

            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText($(this));
                range.select().createTextRange();
                document.execCommand("copy");
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(this);
                window.getSelection().addRange(range);
                document.execCommand("copy");
            }
            showCopyNotification();
        });

        function showCopyNotification() {
            if ($(".not-copy")) {
                $('#not-copy').show();
                setTimeout(() => {
                    $('#not-copy').hide();
                }, 3000);
            }
        }
    }
});