document.addEventListener("turbolinks:load", function () {
  //user profile phone
  if (document.getElementById("user_phone")) {
    document
      .getElementById("user_phone")
      .addEventListener("input", function (e) {
      });
  }


  //Whatsapp checkbox and number
  var _selector = document.querySelector("input[name=whatsapp-checkbox]");

  if (document.getElementById("phone_mask")) {
      //Keep the phone format
      document
        .getElementById("phone_mask")
        .addEventListener("input", function (e) {/*
          var x = e.target.value
            .replace(/\D/g, "")
            .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
          e.target.value = !x[2]
            ? x[1]
            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      */});

    var input = document.getElementById("phone_mask");
    var footer_phone = document.getElementById("cart_phone");
    var footer_check = document.getElementById("checkbox_whatsapp");

    //load session temp variable
    if(sessionStorage.getItem('temp_phone')){
      input.value = sessionStorage.getItem('temp_phone');
      footer_phone.value = input.value;
    }
    if(sessionStorage.getItem('temp_phone')){
      input.value = sessionStorage.getItem('whatsapp_notification');
      footer_check.checked = true;
    }
    //Listener text input
    input.addEventListener("change", function (event) {
      sessionStorage.setItem('temp_phone',input.value);
    });

    //Set unchecked value to checkbox on refresh page
    if(sessionStorage.getItem('whatsapp_notification')){
      var wn = sessionStorage.getItem('whatsapp_notification');
      if(wn == 0){
        input.value = "";
        input.disabled = true;
        input.required = false;
        footer_phone.value = "";
        footer_phone.disabled = true;
        footer_phone.required = false;
        _selector.checked = false;
        footer_check.checked = false;
      }else if (wn == 1){
        _selector.checked = true;
        footer_check.checked = true;
        if(sessionStorage.getItem('temp_phone')){
          input.value = sessionStorage.getItem('temp_phone');
          input.disabled = false;
          input.required = true;
          footer_phone.value = sessionStorage.getItem('temp_phone');;
          footer_phone.disabled = false;
          footer_phone.required = true;
        }else{
          input.value = '';
          input.disabled = false;
          input.required = true;
          footer_phone.value = '';
          footer_phone.disabled = false;
          footer_phone.required = true;
        }
      }
    }
   

    //listener to checkbox
    _selector.addEventListener("change", function (event) {

      if (_selector.checked) {
        //whatsapp phone
        input.value = "";
        input.disabled = false;
        input.required = true;
        //hidden field at the _total.html.erb
        footer_phone.value = "";
        footer_phone.disabled = false;
        footer_phone.required = true;
        sessionStorage.setItem('whatsapp_notification', 1)
        footer_check.checked = true;
      } else {
        //whatsapp phone
        input.value = "";
        input.disabled = true;
        input.required = false;

        closePhoneNotification();

        //hidden field at the _total.html.erb
        //footer_phone.value = "";
        //footer_phone.disabled = true;
        //footer_phone.required = false;

        if(sessionStorage.getItem('temp_phone')){
          sessionStorage.setItem('temp_phone','');
        }
        sessionStorage.setItem('whatsapp_notification', 0)
        footer_check.checked = false;
      }
      // console.log("whatsapp notification value: "+ sessionStorage.getItem('whatsapp_notification'))
    });
  }

  //update values of the cart_phone at the cart footer
  // function updateCartFooterPhone() {
  //   var whatsapp_input = document.getElementById("phone_mask").value;
  //   document.getElementById("cart_phone").value = whatsapp_input;
  // }

 
  if(document.getElementById('checkout-button') && document.getElementById('phone_mask') ){
    if($("#phone_mask").val().length < 13){
      $("#cart_phone").val("");
    }
    $("#phone_mask").on('keyup', function(){
      if($("#phone_mask").val().length < 13){
        $("#cart_phone").val("");
      }else{
        $("#cart_phone").val($("#phone_mask").val());
      }
      //console.log("cart_phone value: "+ document.getElementById("cart_phone").value);
    });
    $("#cart_phone").val("");
    $("#checkbox_whatsapp").checked = footer_check;
  }

  $("#phone_mask").on('change', function(){
    if(document.getElementById("phone_mask").value.length > 13){
      document.getElementById("cart_phone").value = document.getElementById("phone_mask").value;
      //console.log("footer phone value: "+ document.getElementById("cart_phone").value)
    }
  });

  //listener the submit button
  if(document.getElementById('checkout-button') && document.getElementById('phone_mask')){

    footer_phone.value = input.value;
    //console.log("footer phone value: "+ document.getElementById("cart_phone").value);

    document.getElementById("checkout-button").addEventListener("click", function () {
      let phone_number = document.getElementById("phone_mask").value;
      let notification = document.getElementById("whatsapp-notification");
      //show whatsapp phone notification
      if (_selector.checked) {
        // if (phone_number != "") {
        //   if (phone_number.length < 14) {
        //     if (notification.classList.contains("is-hidden")) {
        //       showPhoneNotification(notification);
        //       document.getElementById("whatsapp-notification-message").innerText = "Parece que o número está incompleto.";
        //       //if the number is wrong, I need to clean this field to avoid submit
        //       var footer_phone = document.getElementById("cart_phone");
        //       footer_phone.value = '';
        //     }
        //   }
        // } else {
        //   if (notification.classList.contains("is-hidden")) {
        //     showPhoneNotification(notification);
        //     document.getElementById("whatsapp-notification-message").innerText = 'Por favor, informe seu WhatsApp ou desmarque a opção acima caso não deseje receber notificações deste pedido.';
        //   }
        // }
      }
    });
  
  }

  function closePhoneNotification(){
    let not = document.getElementById("whatsapp-notification");
    if (!not.classList.contains("is-hidden")) {
      not.classList.add('is-hidden');
    }
  }

  function showPhoneNotification(not){
    if (not.classList.contains("is-hidden")) {
      not.classList.remove("is-hidden");
    }
  }

  //Clean session storage items when press pagar
  if(document.getElementById('checkout-button')){
  var checkout_button = document.getElementById('checkout-button');
    checkout_button.addEventListener('click',function(event){
        if(sessionStorage.getItem("whatsapp_notification")){
          sessionStorage.removeItem('whatsapp_notification')
        }
        if(sessionStorage.getItem("temp_phone")){
          sessionStorage.removeItem('temp_phone')
        }
    });
  };

})
