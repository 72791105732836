document.addEventListener("turbolinks:load", function (event) {
 
    if (document.getElementById("x")) {
      var input_card_number = document.getElementById("cardNumber");
      var success_message = document.getElementById("frmCCNum-success-label");
      var error_message = document.getElementById("frmCCNum-error-label");
      var cvv = document.getElementById("securityCode");

      //Input Dollar
      input_card_number.addEventListener("keyup", function (event) {
        check_input(input_card_number.value);
      });

      function check_input(value) {
        var result = valid_credit_card(value);
        var card_type = null;

        if (result == true) {
          if (value.length >= 13) {
            card_type = GetCardType(value);
            success_message.innerText = card_type;
            error_message.innerText = "";
           
            if (card_type == "AMEX") {
              cvv.setAttribute("maxlength", "4");
            } else {
              cvv.setAttribute("maxlength", "3");
            }
            
            updateFormStyle("is-success");
          }
        } else {
          if (value.length >= 16) {
            updateFormStyle("is-danger");
            success_message.innerText = "";
            error_message.innerText = "Verifique o número do cartão";
          } else {
            error_message.innerText = "";
            success_message.innerText = "";
            cleanInputStyle();
          }
        }
      }

      function updateFormStyle(value) {
        arr = input_card_number.className.split(" ");
        if (arr.indexOf(value) <= -1) {
          input_card_number.className += " " + value;
        }
      }

      function cleanInputStyle(className) {
        input_card_number.className = input_card_number.className.replace(
          /\bis-success\b/g,
          ""
        );
        input_card_number.className = input_card_number.className.replace(
          /\bis-danger\b/g,
          ""
        );
      }

      //https://gist.github.com/DiegoSalazar/407553
      // Takes a credit card string value and returns true on valid number
      function valid_credit_card(value) {
        // Accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(value)) return false;

        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0,
          bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
          var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

          if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

          nCheck += nDigit;
          bEven = !bEven;
        }

        return nCheck % 10 == 0;
      }

      function GetCardType(number) {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null) return "Visa";

        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        if (
          /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
            number
          )
        )
          return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null) return "AMEX";

        // Discover
        re = new RegExp(
          "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
        );
        if (number.match(re) != null) return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null) return "Diners";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null) return "Diners - Carte Blanche";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null) return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null) return "Visa Electron";

        return "";
      }
    }
  });

