document.addEventListener("turbolinks:load", function(event) {

    if(document.getElementById('_card-submit')){
        
        let btn = document.getElementById('card-submit');
        btn.addEventListener("click", function (event) {
            lockButton(btn);
        });
    }

    function lockButton(btn){
        btn.classList.add('is-loading');
    }


/*
    //ADD SPIN TO ANY SUBMIT BTN
    if(document.getElementById("card-submit")){
        document.querySelectorAll('.submit').forEach(item => {
            item.addEventListener('click', event => {
                item.classList.add('is-loading');
            /*
            if(document.getElementsByClassName('credit-card-input')){
                card_inputs = document.getElementsByClassName('credit-card-input');
                for (let index = 0; index < card_inputs.length; index++) {
                let element =  card_inputs[index];
                element.disabled = true;
                }
            } 

            })
        })
    }  */

    // ENABLE DISABLE CREDIT CARD SUBMIT
    if(document.getElementById("_card-submit")){
        
        var card_submit = document.getElementById('card-submit');
        card_submit.disabled = true;

        var card_number = document.getElementById('cardNumber');
        var card_name = document.getElementById('cardholderName');
        var card_code = document.getElementById ('securityCode');
        var doc_number = document.getElementById('docNumber');
        var month = document.getElementById("cardExpirationMonth");
            
        card_number.addEventListener('keyup',function(){
            checkForms();
        });

        card_name.addEventListener('keyup',function(){
            checkForms();
        });

        card_code.addEventListener('keyup',function(){
            checkForms();
        });
  
        doc_number.addEventListener('keyup',function(){
            checkForms();
        });

        month.addEventListener('change',function(){
            checkForms();
        });
    }

    function checkForms(){
        if( 
            card_code.value.length >= 3 &&
            card_number.value.length >= 13 &&
            card_name.value.trim().indexOf(' ') != -1 &&
            doc_number.value.length > 10
            ){
            card_submit.disabled = false;
        }else{
            card_submit.disabled = true;
        }
    } 






    function updateStyle(el, value){
        if(value == true){
            let name, arr;
            name = "is-success";
            arr = el.className.split(" ");
            if (arr.indexOf(name) == -1) {
                el.className += " " + name;
            }
        }else{
            el.className = el.className.replace(
                /\bsuccess\b/g,
                ""
            );
        }
    }



});