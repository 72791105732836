document.addEventListener("turbolinks:load", function() {
  function scrollAnchors(e, respond = null) {
    e.preventDefault();
    const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

    var targetID = respond
      ? respond.getAttribute("href")
      : this.getAttribute("href");
    const targetAnchor = document.querySelector(targetID);
    if (!targetAnchor) return;

    const originalTop = distanceToTop(targetAnchor) - 150;

    window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
    const checkIfDone = setInterval(function() {
      const atBottom =
        window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = "-1";
        window.history.pushState("", "", targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }

  const links = document.querySelectorAll(".move-scroll");
  links.forEach(each => (each.onclick = scrollAnchors));

   //Ao terminar de carregar a tela verifica se a URL indica para scrollar para alguma sessão
  getUrlVars();
  function getUrlVars() {
    var vars = [],hash;
    var hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split("=");
      vars[hash[0]] = hash[1];
    }
    scroll(vars);
  }
  function scroll(vars){
    var url_vars = vars;
    for (var i in url_vars) {
      //console.log(url_vars[i]);
        if (document.getElementById(url_vars[i]) != null) {
        document.getElementById(url_vars[i]).scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
  }
  getUrlVars();

});




window.onload = function() {

   //Ao terminar de carregar a tela verifica se a URL indica para scrollar para alguma sessão
   getUrlVars();
   function getUrlVars() {
     var vars = [],hash;
     var hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
     for (var i = 0; i < hashes.length; i++) {
       hash = hashes[i].split("=");
       vars[hash[0]] = hash[1];
     }
     scroll(vars);
   }
   function scroll(vars){
     var url_vars = vars;
     for (var i in url_vars) {
       //console.log(url_vars[i]);
         if (url_vars[i] == "testmonials-section" || url_vars[i] == "who-we-are"  ) {
         document.getElementById(url_vars[i]).scrollIntoView({ block: "start", behavior: "smooth" });
       }
     }
   }
   getUrlVars();
 
}
