document.addEventListener("turbolinks:load", function() {
  var element = document.getElementsByClassName("hero-image");

  if (document.body.classList.contains("hero-image")) {
    heroRepos();
  }
 
  window.addEventListener("resize", heroRepos);

  function heroRepos() {
    if (document.body.classList.contains("hero-image")) {
        
      var ref = 1800;
      var defaultRightPosition = -400;

      var win =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      var final_val = 0;

      final_val = (win - ref) * 0.5 + defaultRightPosition;
      document.body.style.backgroundPosition ="right " + final_val + "px top -650px";
    }

    var x = 0;
    var txt = (x += 1);
   // console.log(txt);
  }
});

