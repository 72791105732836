import './scroll';
import './carousel';
import './notifications';
import './menu';
import './phone_mask';
import './hero_position';
import './trim_username';
import './message_force_translation';
import './google-tag-manager-trigger';
import './simulator';
import './luhn';
import './btn_submit';
import './mp_cpf';
import './userFormValidation';
import './dropdown';
import './modal';
import  './text-copy';
import './credit_card_user_phone';
import './user_phone';
import './masks';
