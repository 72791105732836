document.addEventListener("turbolinks:load", async function () {

    if (document.getElementById("simulator")) {

        var dollar_glin_view = document.getElementById("simulator-dollar-glin-view");
        var subtotal = document.getElementById("simulator-subtotal");
        var glin_fee = document.getElementById("simulator-glin-fee");
        var iof = document.getElementById("simulator-iof");

        var input_dollar = document.getElementById("input-dollar");
        var input_real = document.getElementById("input-real");

        //Input Dollar
        input_dollar.addEventListener("keyup", function (e) {
            checkKeys(e);
        });


        function checkKeys(e) {
            if (((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode == 0 || e.keyCode == 229)) {
                keyPress();
            }
        }

        // UPDATE DOLLAR GLIN =========================================================
        async function updateDollarGlin() {
            $.ajax({
                async: true,
                type: "GET",
                url: '/glinpay_dollar_glin',
                dataType: 'json',
                data: {
                    amount: input_dollar.value
                },
                success: function (response) {
                    dollar_glin_view.innerText = response.conversionRate.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });

                    iof.innerText = response.taxAmount.amount.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });
                    subtotal.innerText = response.convertedAmount.amount.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });
                    glin_fee.innerText = response.serviceFeeAmount.amount.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });

                    input_real.value = response.options[0].totalDueAmount.amount.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    }).replace("R$", "");

                }
            });
        }


        //KEY PRESS =========================================
        function keyPress() {
            let value;
            value = input_dollar.value;
            value = value.replace(/\,/g, "");
            value = value.replace(/\./g, "");
            if (value > 0) {
                value = round(value);
                input_dollar.value = value;
                debouncedUpdateDollarGlin();
            }
        }

        //ROUND NUMBER ===========================================
        function round(r) {
            return Math.ceil(r / 1) * 1;
        }

        // DEBOUNCE =============================================
        function debounce(func, wait) {
            let timer;
            return function () {
                clearTimeout(timer);
                timer = setTimeout(func, wait);
            }
        }

        let debouncedUpdateDollarGlin = debounce(() => updateDollarGlin(), 500);

        // START VALUE ==========================================
        input_dollar.value = "100";
        await updateDollarGlin();
    }
});
