// javascript/controllers/file.js
import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["source", "name", "doc"];
    }

    connect() {
    }

    disconnect() {
    }

    change() {
        if (this.sourceTarget.files.length > 0) {
            let sourceName = this.sourceTarget.files[0].name;
            this.nameTarget.textContent = sourceName;
        }
    }

    submit() {
        if (this.sourceTarget.files.length > 0) {
            let target = "upload_doc_" + this.element.id
            //console.log(target)
            let sourceName = this.sourceTarget.files[0].name;
            this.nameTarget.textContent = sourceName;
            document.getElementById(target).submit();
        }
    }
}