var phone_present = false;
var phone_check = false;

document.addEventListener("turbolinks:load", function () {
  //user profile phone
  if (document.getElementById("credit_card_user_phone")) {
    document
      .getElementById("credit_card_user_phone")
      .addEventListener("input", function (e) {/*
        if (document.getElementById("ddi_code").value.split("+")[1] == "55"){
          var x = e.target.value
              .replace(/\D/g, "")
              .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
          e.target.value = !x[2]
              ? x[1]
              : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        }
        else if (document.getElementById("ddi_code").value.split("+")[1] == "1"){
          var x = e.target.value
              .replace(/\D/g, '')
              .match(/(\d{3})(\d{3})(\d{4})/);
          e.target.value = !x[2]
              ? x[1]
              : '(' + x[1] + ') ' + x[2] + '-' + x[3];
        }
      */});
  }

  if (document.getElementById("credit_card_user_phone")) {
    phone_present = true;
    checkPhone("start");
    $("#credit_card_user_phone").keyup(function () {
      checkPhone("x");
    });
  }

  function checkPhone(value) {
    let str = $("#credit_card_user_phone").val();
    //console.log("Phone Number : " + str)
    //console.log("Number size : " + str.length)
    if (str.length < 15) {
      phone_check = false
      if (value != "start") {
        $("#phoneError").removeClass("is-hidden");
      }
      if (value == 'start') {
        $("#credit_card_user_phone").val("");
      }
    } else {
      phone_check = true
      if (value != "start") {
        $("#phoneError").addClass("is-hidden");
      }
    }
    checkOk();
  }

  function checkOk() {
    if (phone_present == true && phone_check == true) {
      $("#card-submit").attr("disabled", false);
    } else {
      $("#card-submit").attr("disabled", true);
    }
  }

})
