// some messages come with attributes that can't be translated using regular methods

document.addEventListener("turbolinks:load", function () {
  if (document.getElementsByClassName("error_message")) {
    var messages = document.getElementsByClassName("error_message");

    for (let i = 0; i < messages.length; i++) {
      // console.log(messages[i].innerText);

      if (messages[i].innerHTML.indexOf("Password") > -1) {
        let message = messages[i].innerHTML;
        let index = -1;

        //Password confirmation
        if ((index = message.indexOf("Password confirmation")) > -1) {
          let newM = message.replace("Password confirmation", "");
          messages[i].innerHTML = newM;
        } else if((index = message.indexOf("Password")) > -1) {
         let newM = message.replace("Password", "Senha");
         messages[i].innerHTML = newM;
       } 

      }
    }
  }
});
