//CHECK IF THE ELEMENT IS PRESENT
var name_present = false;
var phone_present = false;
var cpf_present = false;
var birthday_present = false;
var doc_date_present = false; //CART
var doc_date_issue_present = false; // PROFILE
var doc_date_exp_present = false; // PROFILE
var doc_number_present = false; // CART
var doc_number_profile_present = false; // PROFILE

//CHECK IF THE ELEMENT IF FILLED
var name_check = false;
var phone_check = false;
var cpf_check = false;
var birthday_check = false;
var doc_date_check = false; // CART
var doc_date_issue_check = false; // PROFILE
var doc_date_exp_check = false; // PROFILE
var doc_number_check = false; // CART
var doc_number_profile_check = false;// PROFILE

document.addEventListener("turbolinks:load", function (event) {

    let checks = []
    //CPF
    if (document.getElementById("user_document_id")) {
        cpf_present = true;
        setCpf();
        $("#user_document_id").on('keyup', function () {
            setCpf();
        });
    }

    //NAME
    if (document.getElementById("user_name")) {
        name_present = true;
        checkName("start");
        $("#user_name").keyup(function () {
            checkName("x");
        });
        $("#user_name").change(function () {
            checkName("y");
        });
    }

    //PHONE
    if (document.getElementById("user_phone")) {
        $('#user_phone').on('input', function (event) {
            this.value = this.value.replace(/[^0-9]/g, '');
        });

        phone_present = true;
        checkPhone("start");
        $("#user_phone").keyup(function () {
            checkPhone("x");
        });
        $("#user_phone").change(function () {
            checkPhone("y");
        });
    }

    //DOC NUMBER
    // if (document.getElementById("doc-number-visible")) {
    //     doc_number_present = true;
    //     checkDocNumber($("#doc-number-visible").val());
    //     $("#doc-number-visible").keyup(function () {
    //         checkDocNumber(this.value)
    //     });
    // }

    //DOC NUMBER PROFILE
    if (document.getElementById("user_doc_number")) {
        doc_number_profile_present = true;
        checkUserDocNumber('start');
        $("#user_doc_number").keyup(function () {
            checkUserDocNumber('x')
        });
    }

    //BIRTHDAY
    if (document.getElementById("birthday_date_3i") || document.getElementById("user_birthday_3i")) {
        birthday_present = true;
        checkBirthday('start');
    }

    //DOC DATE
    if (document.getElementById("doc_date_3i") || document.getElementById("user_doc_issue_date_3i")) {
        doc_date_present = true;
        checkDocDate('start');
    }

    //DOC PROFILE DATE (PROFILE)
    if (document.getElementById("user_doc_type_id")) {
        // console.log("DOC_USERTYPE_ID FOUND");
        checkProfileDoc();
        $("#user_doc_type_id").on("change", function () {
            checkProfileDoc();
        });
    }

    function checkProfileDoc() {
        // console.log("DOC_USERTYPE_ID FOUND");
        let doc_selected = $('#user_doc_type_id').find(":selected").val();
        // console.log("DOC_ID: " + doc_selected );

        if (doc_selected == 1) {
            doc_date_issue_present = true;
            doc_date_exp_present = false;
            checkDocIssueDate('start');
        } else {
            doc_date_issue_present = false;
            doc_date_exp_present = true;
            checkDocExpDate('start');
        }
    }

});


// ============================ CPF =====================================

var cpf_check_count = 0
function setCpf(start) {

    var cpf = document.getElementById("user_document_id");
    let test = cleanCpf(cpf.value);
    test = TestCPF(test);

    if (test == true) { // IF CPF is CORRECT

        //$(cpf).addClass("is-success");
        cpf.value = formatCPF(cpf.value);

        cpf_check = true;

        //GENERIC CHECK
        checkAll();

        //If in the carts, update cart_phone hidden form at cart_total.html.erb
        if (document.getElementById('checkout-button')) {
            document.getElementById('document_id').value = cpf.value;
        }
        if (cpf_check_count > 0) {
            $("#docNumberError").addClass("is-hidden");
        }
    } else { // IF CPF is not CORRECT
        cpf_check = false;

        //$(cpf).removeClass("is-success");
        cpf.value = cleanCpf(cpf.value);

        //CART CPF
        if (document.getElementById('checkout-button')) {
            document.getElementById('document_id').value = "";
        }
        if (cpf_check_count > 0) {
            $("#docNumberError").removeClass("is-hidden");
        }
        checkAll();
    }
    cpf_check_count ++;
}

function TestCPF(cpf) {
    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11)
        return false;
    for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
            digitos_iguais = 0;
            break;
        }
    if (!digitos_iguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--)
            soma += numeros.charAt(10 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--)
            soma += numeros.charAt(11 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;
        return true;
    } else
        return false;
}

function formatCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function cleanCpf(val) {
    val = val.replace(/\./g, "");
    val = val.replace(/\-/g, "");
    return val;
}


// ============================ NAME ====================================

function checkName(value) {

    let str = $("#user_name").val();
    //var regexp = /[a-zA-Z]+\s+[a-zA-Z]+/g;

    if (str.trim().indexOf(' ') != -1) { //If name is CORRECT

        name_check = true;
        if (value != "start") {
            $("#nameError").addClass("is-hidden");
        }

        //If in the carts, update cart_phone hidden form at cart_total.html.erb
        if (document.getElementById('name')) {
            document.getElementById('name').value = str;
        }
        //GENERIC CHECK
        checkAll();

    } else { //If name is INCORRECT

        name_check = false;
        if (value != "start") {
            $("#nameError").removeClass("is-hidden");
        }

        //If in the carts, update cart_phone hidden form at cart_total.html.erb
        if (document.getElementById('name')) {
            document.getElementById('name').value = '';
        }
        if (value == 'start') {
            $("#user_name").val("");
        }
        checkAll();
    }
    value = '';
}

// ============================ PHONE ====================================

function checkPhone(value) {
    let str = $("#user_phone").val();
    //console.log("Phone Number : " + str)
    //console.log("Number size : " + str.length)

    let ddi = '';

    if (document.getElementById("ddi_code")) {
        ddi = document.getElementById("ddi_code").value.split('+')[1];
    }

    if ((ddi == '55') || (ddi == '')) {
        checkPhoneLength(value, str, 11)
    } else if (ddi == '1') {
        checkPhoneLength(value, str, 10)
    } else {
        checkPhoneLength(value, str, 5)
    }


    checkAll();
}

function checkPhoneLength(value, str, max_length){
    if (str.length < max_length) {
        phone_check = false
        if (value != "start") {
            $("#phoneError").removeClass("is-hidden");
        }
        if (value == 'start') {
            $("#user_phone").val("");
        }
    } else {
        phone_check = true
        if (value != "start") {
            $("#phoneError").addClass("is-hidden");
        }

    }
}

// ============================ DOC NUMBER ====================================

// function checkDocNumber(value) {
//     //console.log("Check Number : " + value)
//     if (value.length >= 4) {
//         doc_number_check = true
//         if($("#user_rg")){
//             $("#user_rg").addClass("is-hidden");
//         }
//     } else {
//         doc_number_check = false
//         if($("#user_rg")){
//             $("#user_rg").removeClass("is-hidden");
//         }
//     }
//     checkAll();
//
// }

// ============================ DOC NUMBER PROFILE ====================================

function checkUserDocNumber(value) {
    let str = $("#user_doc_number").val();
    //console.log("Check Number : " + str)
    if (str.length >= 4) {
        doc_number_profile_check = true
        if (value != "start") {
            $("#docError").addClass("is-hidden");
        }

    } else {
        doc_number_profile_check = false
        if (value != "start") {
            $("#docError").removeClass("is-hidden");
        }
        if (value == 'start') {
            $("#user_doc_number").val("");
        }

    }
    checkAll();
}

// ============================ BIRTHDAY =====================================


function checkBirthday(value) {

    var day = null;
    var month = null;
    var year = null;
    var id = null;

    if (document.getElementById("user_birthday_3i")) {
        id = "#user_birthday_";
    } else if (document.getElementById("birthday_date_3i")) {
        id = "#birthday_date_";
    }
    day = $(id + '3i').val();
    month = $(id + '2i').val();
    year = $(id + '1i').val();

    setBirthday(day, month, year, value);

    $(id + "3i").on("change", function () {
        day = $(id + '3i').val();
        setBirthday(day, month, year, value);
    })

    $(id + "2i").on("change", function () {
        month = $(id + '2i').val();
        setBirthday(day, month, year, value);
    })

    $(id + "1i").on("change", function () {
        year = $(id + '1i').val();
        setBirthday(day, month, year, value);
    })
    value = '';
}

function setBirthday(day, month, year, value) {

    if (day != "" && month != "" && year != "") { // IF DATE IS COMPLETE]
        birthday_check = true;
        if (value != "start") {
            $("#birthdayError").addClass("is-hidden");
        }
    } else { // IF DATE IS INCOMPLETE
        birthday_check = false;
        if (value != "start") {
            $("#birthdayError").removeClass("is-hidden");
        }
    }
    checkAll();
}

// =========================== CHECK DOC DATE ==============================

// function checkDocDate(value) {
//
//     let day = $('#doc_date_3i').val();
//     let month = $('#doc_date_2i').val();
//     let year = $('#doc_date_1i').val();
//
//     setDocDate(day, month, year, value);
//
//     $("#doc_date_3i").on("change", function () {
//         day = $('#doc_date_3i').val();
//         setDocDate(day, month, year, value);
//     })
//
//     $("#doc_date_2i").on("change", function () {
//         month = $('#doc_date_2i').val();
//         setDocDate(day, month, year, value);
//     })
//
//     $("#doc_date_1i").on("change", function () {
//         year = $('#doc_date_1i').val();
//         setDocDate(day, month, year, value);
//     })
//     value = '';
// }


// =========================== CHECK DOC DATE PROFILE ==============================

function checkDocDate(value) {

    var day = null;
    var month = null;
    var year = null;
    var id = null;

    if (document.getElementById("user_doc_issue_date_3i")) {
        id = "#user_doc_issue_date_";
    } else if (document.getElementById("doc_date_3i")) {
        id = "#doc_date_";
    }
    day = $(id + '3i').val();
    month = $(id + '2i').val();
    year = $(id + '1i').val();

    setDocDate(day, month, year, value);

    $(id + "3i").on("change", function () {
        day = $(id + '3i').val();
        setDocDate(day, month, year, value);
    })

    $(id + "2i").on("change", function () {
        month = $(id + '2i').val();
        setDocDate(day, month, year, value);
    })

    $(id + "1i").on("change", function () {
        year = $(id + '1i').val();
        setDocDate(day, month, year, value);
    })
    value = '';
}

function setDocDate(day, month, year, value) {

    if (day != "" && month != "" && year != "") { // IF DATE IS COMPLETE
        doc_date_check = true;
        if (value != "start") {
            $("#docDateError").addClass("is-hidden");
        }
    } else { // IF DATE IS INCOMPLETE
        doc_date_check = false;
        if (value != "start") {
            $("#docDateError").removeClass("is-hidden");
        }
    }
    checkAll();
}

// =========================== CHECK EXP DATE ==============================

function checkDocExpDate(value) {

    let day = $('#user_doc_exp_date_3i').val();
    let month = $('#user_doc_exp_date_2i').val();
    let year = $('#user_doc_exp_date_1i').val();

    setDocExpDate(day, month, year, value);

    $("#user_doc_exp_date_3i").on("change", function () {
        day = $('#user_doc_exp_date_3i').val();
        setDocExpDate(day, month, year, value);
    })

    $("#user_doc_exp_date_2i").on("change", function () {
        month = $('#user_doc_exp_date_2i').val();
        setDocExpDate(day, month, year, value);
    })

    $("#user_doc_exp_date_1i").on("change", function () {
        year = $('#user_doc_exp_date_1i').val();
        setDocExpDate(day, month, year, value);
    })
    value = '';
}

function setDocExpDate(day, month, year, value) {

    if (day != "" && month != "" && year != "") { // IF DATE IS COMPLETE
        doc_date_exp_check = true;
        if (value != "start") {
            $("#docExpError").addClass("is-hidden");
        }
    } else { // IF DATE IS INCOMPLETE
        doc_date_exp_check = false;
        if (value != "start") {
            $("#docExpError").removeClass("is-hidden");
        }
    }
    checkAll();
}


// =========================== CHECK ISSUE DATE ==============================

function checkDocIssueDate(value) {

    let day = $('#user_doc_issue_date_3i').val();
    let month = $('#user_doc_issue_date_2i').val();
    let year = $('#user_doc_issue_date_1i').val();

    setDocIssueDate(day, month, year, value);

    $("#user_doc_issue_date_3i").on("change", function () {
        day = $('#user_doc_issue_date_3i').val();
        setDocIssueDate(day, month, year, value);
    })

    $("#user_doc_issue_date_2i").on("change", function () {
        month = $('#user_doc_issue_date_2i').val();
        setDocIssueDate(day, month, year, value);
    })

    $("#user_doc_issue_date_1i").on("change", function () {
        year = $('#user_doc_issue_date_1i').val();
        setDocIssueDate(day, month, year, value);
    })
    value = '';
}

function setDocIssueDate(day, month, year, value) {

    if (day != "" && month != "" && year != "") { // IF DATE IS COMPLETE
        doc_date_issue_check = true;
        if (value != "start") {
            $("#docIssueError").addClass("is-hidden");
        }
    } else { // IF DATE IS INCOMPLETE
        doc_date_issue_check = false;
        if (value != "start") {
            $("#docIssueError").removeClass("is-hidden");
        }
    }
    checkAll();
}


// =========================== CHECK ALL ==============================


function checkAll() {

    arr = [
        [name_present, name_check],
        [phone_present, phone_check],
        [cpf_present, cpf_check],
        [birthday_present, birthday_check],
        [doc_date_present, doc_date_check],
        [doc_date_issue_present, doc_date_issue_check],
        [doc_date_exp_present, doc_date_exp_check],
        [doc_number_present, doc_number_check],
        [doc_number_profile_present, doc_number_profile_check],
    ]
    arr2 = []

    for (let i = 0; i < arr.length; i++) {

        if (arr[i][0] == true) {
            arr2.push(arr[i][1]);
        }
    }
    //console.log(arr);
    //console.log(arr2);

    let countChecks = 0
    for (let i = 0; i < arr2.length; i++) {
        if (arr2[i] == true) {
            countChecks++;
            /*if (countChecks == arr2.length) {
                $(".submit-to-disable").attr("disabled", false);
            } else {
                $(".submit-to-disable").attr("disabled", true);
            }*/
        }
    }
    //console.log("countChecks: ", countChecks);
    //console.log("arr2.length: ", arr2.length);
    //console.log("are they equal?  ", (countChecks == arr2.length));
    if (countChecks == arr2.length) {
        //console.log("Ativando o botão");
        $(".submit-to-disable").attr("disabled", false);
    } else {
        //console.log("Desativando o botão");
        $(".submit-to-disable").attr("disabled", true);
    }

}