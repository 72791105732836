// javascript/controllers/carts.js
import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["term", "phone", "document_id", "name", "day", "month", "year"];
  }

  accept(event) {
    let el = document.getElementById(this.data.get('check'));
    el.value = this.termTarget.checked;
  }

  phone(event) {
    let el = document.getElementById(this.data.get('phone'));
    el.value = this.phoneTarget.value;
  }

  document_id(event) {
    let el = document.getElementById(this.data.get('document_id'));
    el.value = this.documentIdTarget.value;
  }
  
  name(event) {
    let el = document.getElementById(this.data.get('name'));
    el.value = this.nameIdTarget.value;
  }

  //BIRTHDAY
  day(event) {
    let el = document.getElementById(this.data.get('day'));
    el.value = this.dayIdTarget.value;
  }
  month(event) {
    let el = document.getElementById(this.data.get('month'));
    el.value = this.monthIdTarget.value;
  }
  year(event) {
    let el = document.getElementById(this.data.get('year'));
    el.value = this.yearIdTarget.value;
  }
}
