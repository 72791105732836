import consumer from "./consumer"

consumer.subscriptions.create("DollarPriceChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const priceContainer = document.getElementById('dollar-price');
    const subtotalPriceContainer = document.getElementById('dollar-price-subtotal');
    const totalOrderValue = document.getElementById('total-usd-value');
    const totalOrderPrice = document.getElementById('dollar-price-total');

    priceContainer.textContent = data.price;
    subtotalPriceContainer.textContent = data.price;
    totalOrderPrice.textContent = (parseFloat(totalOrderValue.textContent) * data.price).toFixed(2);
  }
});
